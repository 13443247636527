import React, { useEffect, useState, TouchEvent, TouchList, Fragment } from 'react'
import "../../../App.css"
import $ from 'jquery'
import { parseJWT } from '../../../utils';
const { default: axios } = require('axios');
import { ToastContainer, toast, Flip } from "react-toastify";
import { Editor } from './Editor'
import { Insights } from "./Insights"
import { AgentWin } from './AgentWin'
import { useTranslation } from 'react-i18next';
import { Opportunities } from './Opportunities';
import i18n from '../../../i18n';
import { Combobox, Transition } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/outline";
import { ReactComponent as CheckedIcon } from "../../../assets/svg/checked.svg"
import { ReactComponent as UncheckedIcon } from "../../../assets/svg/unchecked.svg";
import { v4 as uuidv4 } from 'uuid';
import ConfirmModal from './ConfirmModal';

interface RightComponentProps {
    document: string,
    openedDocument: string,
    documentUpdated: boolean,
    styleCenter: any,
    documentAction: string,
    setDocumentUpdated: any,
    promptList: any
    tender_tab: any
    tender_title: any
    selectedDocumentList: any[]
    promptDataFetch: any
    summaries: any[]
    handleDocumentClick: any
    currentSlide: number
    setCurrentSlide: any
    selectedFolderID: any
    previousOpenedDocument: string
    opportunities: any[]
    isChatWindowClosed: boolean
    opportuniteisDataFetch: any
    setTenderList: any
    selectedTab: any
    setOpenedStatus: any
    handleSelectedFolder: any
    cpvCodes: any[]
    summaryDataFetch: any
    setSettingFinished: any
    isSettingFinished: any
    setLoadingStatue: any
    tenderList: any
    handleSelectedTab: any
    tender_id: any
    setTabName: any
    setSummaryList: any
    dateType: any
    setDateType: any
    setCurrentDate: any
    currentDate: any
    cpvCodesFetch: any
    companyKnowledgeBaseId: string
}


export const RightComponent: React.FC<RightComponentProps> = ({ setSummaryList, document, openedDocument, documentUpdated, styleCenter, documentAction, setDocumentUpdated, promptList, tender_tab, tender_title, selectedDocumentList, promptDataFetch, summaries, handleDocumentClick, currentSlide, setCurrentSlide, selectedFolderID, previousOpenedDocument, opportunities, isChatWindowClosed, opportuniteisDataFetch, setTenderList, selectedTab, setOpenedStatus, handleSelectedFolder, cpvCodes, summaryDataFetch, setSettingFinished, isSettingFinished, setLoadingStatue, tenderList, handleSelectedTab, tender_id, setTabName, dateType, setDateType, setCurrentDate, currentDate, cpvCodesFetch, companyKnowledgeBaseId }) => {
    let user = localStorage.getItem("tender_auth") ? parseJWT(localStorage.getItem("tender_auth")) : null;
    const [showModal, setShowModal] = useState(false);
    const [promptTitle, setPromptTitle] = useState("");
    const [promptText, setPromptText] = useState("");
    const [isGlobalInsight, setGlobalInsight] = useState(false)
    const [promptId, setPromptId] = useState("");
    const [modalAction, setModalAction] = useState("Creation");
    const [showFeedBackModal, setShowFeedbackModal] = useState(false)
    const [showInsightDeleteModal, setShowInsightDeleteModal] = useState(false)
    const [isGlobalInsightInModal, setGlobalInsightInModal] = useState(false)
    const [selectedText, setSelectedText] = useState('')
    const [message, setMessage] = useState<any>('')
    const [autoFilling, setAutoFilling] = useState<any>(false)
    const [isGettingAnswer, setGettingAnswer] = useState(false)
    const [tabData, setTabData] = useState<any[]>([]);
    const [refDocs, setRefDocs] = useState<any[]>([]);
    const [selected, setSelected] = useState("");
    const [query, setQuery] = useState("");
    const { t } = useTranslation();
    const [complianceData, setComplianceData] = useState<any[]>([]);
    const [selectedTableData, setSelectedTableData] = useState<any>({});
    const [compilanceSaveClicked, setCompilanceSaveClicked] = useState(false);
    const [isReferenceDocument, setIsReferenceDocument] = useState(true)
    const [isFromModal, setIsFromModal] = useState(false)
    const [keyWords, setKeyWords] = useState("")
    const [socketId, setSocketId] = useState<any>("")
    const [insightIDForDelete, setInsightIDForDelete] = useState("")

    useEffect(() => {
        async function saveKeyWords() {
            try {
                const data = new FormData();
                data.append("email", user.sub.email);
                data.append("keyWords", keyWords);
                data.append("document", openedDocument);
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/saveKeyWords", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
            } catch (error) {

            }

        }
        if (keyWords) {
            saveKeyWords()
        }
    }, [keyWords])

    const dataFetch = async () => {
        try {
            const data = new FormData();
            data.append("email", user.sub.email);
            data.append("tab", tender_tab);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getTenders", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            setTabData(result.data.data)
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        dataFetch()
    }, [tender_tab])
    const displayModal = () => {
        setShowModal(true);
        setModalAction("Creation");
        setPromptTitle("");
        setPromptText("");
        setPromptId("");
        setGlobalInsight(false)
    }

    const displayInsightDeleteModal = (id: string, globalInsight: boolean) => {
        setShowInsightDeleteModal(true)
        setGlobalInsight(true)
        setGlobalInsightInModal(globalInsight)
        setInsightIDForDelete(id)
    }
    const filetedDoc: any[] =
        query === ""
            ? refDocs
            : refDocs.filter((doc) =>
                doc.toLowerCase()
                    .replace(/\s+/g, "")
                    .includes(query.toLowerCase().replace(/\s+/g, ""))
            );
    const getAIFeedback = async () => {
        setGettingAnswer(true);
        await new Promise((resolve: any) => {
            resolve();
        });
        const language = i18n.language ? i18n.language : "en"

        let question = selected !== '' ? "By referencing " + selected + ", Please try to make improvement of following text.\n" + selectedText : "Please try to make improvement of following text.\n" + selectedText
        if (language == 'no') {
            question = selected !== '' ? "Ved å referere " + selected + ", Prøv å forbedre følgende tekst.\n" + selectedText : "Prøv å forbedre følgende tekst.\n" + selectedText
        }
        if (!isReferenceDocument) {
            question = "By referencing following keywords, " + keyWords + " Please try to make improvement of following text without making false statements.\n" + selectedText
            if (language == 'no') {
                question = "Ved å referere til følgende nøkkelord, " + keyWords + " Vennligst prøv å forbedre følgende tekst uten å komme med falske påstander.\n" + selectedText
            }
        }
        let params = {
            email: user.sub.email,
            question: question,
            openedDocument: selected,
            selectedDocumentList: [selected],
        }
        const response: any = await fetch(process.env.REACT_APP_API_URL + "api/getAnswer", {
            method: 'POST',
            body: JSON.stringify(params),
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                'Content-Type': 'application/json',
            }
        });
        const reader = response.body.getReader();
        const decoder = new TextDecoder();
        let done = false;
        let messageValue = ''
        while (!done) {
            const { value, done: doneReading } = await reader.read();
            done = doneReading;
            const chunkValue = decoder.decode(value);
            var regex = /([^:]*)json_data:(\{.*?\})/g;
            var match = regex.exec(chunkValue);
            if (match && match.length >= 3) {
                var beforeText = match[1];
                if (beforeText) {
                    messageValue = messageValue + beforeText
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(messageValue, 'text/html');
                    const extractedText = htmlDoc.documentElement.textContent;
                    setMessage(extractedText)
                }
                var jsonData = match[2];
            } else {
                messageValue = messageValue + chunkValue
                if (!chunkValue.includes("</")) {
                    const parser = new DOMParser();
                    const htmlDoc = parser.parseFromString(messageValue, 'text/html');
                    const extractedText = htmlDoc.documentElement.textContent;
                    setMessage(extractedText)
                }
            }
        }
        setGettingAnswer(false)
    }
    const aiFeedbackGetAnswer = async (selectedText: string, pOpenedDocument: string) => {
        try {
            const data = new FormData();
            data.append("email", user.sub.email);
            data.append("keyWords", keyWords);
            data.append("document", openedDocument);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getKeywords", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            setKeyWords(result?.data?.data)

        } catch (error) {

        }
        let refDocs: any[] = []
        tabData.forEach((item: any) => {
            refDocs.push(...item?.documents);
        });
        setRefDocs(refDocs)
        setSelected(refDocs[0])
        setSelectedText(selectedText)

        setShowFeedbackModal(true)
        setMessage('')


    }
    const handleSave = async () => {
        try {
            setShowModal(true);
            if (modalAction === "Creation") {
                const data = new FormData();
                data.append("email", user.sub.email);
                data.append("title", promptTitle);
                data.append("text", promptText);
                data.append('type', 'insight');
                data.append('selectedFolderID', isGlobalInsight ? "" : selectedFolderID);
                data.append('isGlobalInsight', isGlobalInsight ? "Yes" : "No");
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/createPrompt", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    toast.success(t("Successfully Created!"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    promptDataFetch();
                }
            } else if (modalAction === "Edition" && promptId !== "") {
                const data = new FormData();
                data.append("id", promptId);
                data.append("title", promptTitle);
                data.append("text", promptText);
                data.append('type', 'insight');
                data.append('selectedFolderID', isGlobalInsight ? "" : selectedFolderID);
                data.append('isGlobalInsight', isGlobalInsight ? "Yes" : "No");
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/updatePrompt", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    toast.success(t("Successfully Edited!"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    promptDataFetch();
                }
            }
            setShowModal(false);
            setPromptTitle("");
            setPromptText("");
        } catch (error) {
            console.log(error)
        }
    }

    const handleDelete = async (id: string = "", globalInsightDelete: boolean = false) => {
        try {
            let delete_id: string
            id != "" ? delete_id = id : delete_id = promptId
            if (delete_id) {
                const data = new FormData();
                data.append("id", delete_id);
                if (globalInsightDelete) {
                    data.append('isGlobalInsight', isGlobalInsight ? "Yes" : "No");
                    data.append("selectedFolderID", selectedFolderID);
                }
                const result = await axios.post(process.env.REACT_APP_API_URL + "api/deletePrompt", data, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                        "Content-Type": "application/json",
                    }
                });
                if (result.status === 200) {
                    toast.success(t("Successfully Deleted!"), {
                        position: "top-right",
                        autoClose: 1000,
                        hideProgressBar: true,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: false,
                        progress: 0,
                        toastId: "my_toast",
                    });
                    promptDataFetch();
                }
                setShowModal(false);
                setPromptTitle("");
                setPromptText("");
            }
        } catch (error) {
            console.log(error)
        }
    }

    const handleEditPrompt = async (prompt: any) => {
        setShowModal(true);
        setModalAction("Edition");
        setPromptTitle(prompt.title);
        setPromptText(prompt.text);
        setPromptId(prompt.id);
        setGlobalInsight(prompt?.isGlobalInsight == "Yes" ? true : false)
    }

    const updateDocumentText = (text: string) => {
        let documentObject: any = $('#docx_container');
        if (documentObject[0]?.ej2_instances[0]?.documentEditor) {
            let container: any = documentObject[0]?.ej2_instances[0]?.documentEditor
            container.editor.deleteSelectedContents(container.selection, false, false)
            container.editor.insertText(text, true)
        }
        setShowFeedbackModal(false)
    }
    const [slidecount, setSlideCount] = useState(currentSlide);
    useEffect(() => {
        setSlideCount(currentSlide)
    }, [currentSlide])
    const [addedState, setAddedState] = useState(false);
    const [documentUrl, setDocumentUrl] = useState(null);
    const [documentsCount, setDocumentsCount] = useState(0);
    const [clickedHeartIndex, setClickedHeartIndex] = useState<any>([]);
    const [hoverHeartIndex, setHover] = useState(-1);

    const refreshData = () => {
        setAddedState(false);
        opportuniteisDataFetch(currentDate);
        setClickedHeartIndex([]);
        setHover(-1);
    }
    return (
        <>
            <div className="md:w-full lg:w-[50%] text-center bg-bg_body rounded-xl relative" style={styleCenter}
            >
                {addedState ? <ConfirmModal setTenderList={setTenderList} tenderList={tenderList} selectedFolderID={selectedFolderID} setIsFromModal={setIsFromModal} setCurrentSlide={setCurrentSlide} documentsCount={documentsCount} url={documentUrl} refreshData={refreshData} promptDataFetch={promptDataFetch}></ConfirmModal> : <></>}

                <div className='absolute bottom-[10px] left-[45%] z-[1000] flex'>
                    {(() => {
                        const arr: any[] = [];
                        for (let i = 0; i < 4; i++) {
                            arr.push(
                                <img
                                    src={process.env.PUBLIC_URL + `/img/dot${i + 1}${i === slidecount ? `-${i + 1}` : ""}.png`}
                                    width={35}
                                    height={35}
                                    className={`mr-[10px]`}
                                    key={i}
                                    onClick={() => setCurrentSlide(i)}
                                />
                            );
                        }
                        return arr;
                    })()}
                </div>
                <div className={`slidefade ${currentSlide === 0 ? "block" : "hidden"}`}>
                    <Opportunities setSocketId={setSocketId} cpvCodesFetch={cpvCodesFetch} hoverHeartIndex={hoverHeartIndex} setHover={setHover} clickedHeartIndex={clickedHeartIndex} setClickedHeartIndex={setClickedHeartIndex} currentDate={currentDate} setCurrentDate={setCurrentDate} setDocumentUrl={setDocumentUrl} setDocumentsCount={setDocumentsCount} setAddedState={setAddedState} handleSelectedTab={handleSelectedTab} setIsFromModal={setIsFromModal} setCurrentSlide={setCurrentSlide} opportunities={opportunities} isChatWindowClosed={isChatWindowClosed} opportuniteisDataFetch={opportuniteisDataFetch} selectedFolderID={selectedFolderID} setTenderList={setTenderList} selectedTab={selectedTab} setOpenedStatus={setOpenedStatus} handleSelectedFolder={handleSelectedFolder} cpvCodes={cpvCodes} promptDataFetch={promptDataFetch} summaryDataFetch={summaryDataFetch} setSettingFinished={setSettingFinished} isSettingFinished={isSettingFinished} setLoadingStatue={setLoadingStatue} tenderList={tenderList} dateType={dateType} setDateType={setDateType}></Opportunities>
                </div>
                <div className={`slidefade ${currentSlide === 1 ? "block" : "hidden"}`}>
                    <Insights socketId={socketId} setAutoFilling={setAutoFilling} setCurrentSlide={setCurrentSlide} setSummaryList={setSummaryList} setTabName={setTabName} selectedTab={selectedTab} setTenderList={setTenderList} setIsFromModal={setIsFromModal} isFromModal={isFromModal} openedDocument={openedDocument} promptList={promptList} selectedDocumentList={selectedDocumentList} displayModal={displayModal} handleEditPrompt={(prompt) => handleEditPrompt(prompt)} handleDelete={(id) => handleDelete(id)} selectedFolderID={selectedFolderID} promptDataFetch={promptDataFetch} summaries={summaries} handleDocumentClick={handleDocumentClick} displayInsightDeleteModal={displayInsightDeleteModal} tenderList={tenderList} setOpenedStatus={setOpenedStatus} handleSelectedFolder={handleSelectedFolder} companyKnowledgeBaseId={companyKnowledgeBaseId}></Insights>

                </div>
                <div className={`slidefade ${currentSlide === 2 ? "block" : "hidden"}`}>
                    <Editor setAutoFilling={setAutoFilling} autoFilling={autoFilling} document={document} openedDocument={openedDocument} documentUpdated={documentUpdated} styleCenter={styleCenter} currentSlide={currentSlide} documentAction={documentAction} setDocumentUpdated={(val: boolean) => setDocumentUpdated(val)} tender_tab={tender_tab} tender_title={tender_title} aiFeedbackGetAnswer={(text: string, pDocument: string) => {
                        aiFeedbackGetAnswer(text, pDocument)
                    }} previousOpenedDocument={previousOpenedDocument} selectedDocumentList={selectedDocumentList} complianceData={complianceData} setComplianceData={setComplianceData} selectedTableData={selectedTableData} setSelectedTableData={setSelectedTableData} setCurrentSlide={setCurrentSlide} compilanceSaveClicked={compilanceSaveClicked} setCompilanceSaveClicked={setCompilanceSaveClicked} tender_id={tender_id} companyKnowledgeBaseId={companyKnowledgeBaseId}></Editor>
                </div>
                <div className={`${currentSlide === 3 ? "block" : "hidden"}`}>
                    <AgentWin complianceData={complianceData} setComplianceData={setComplianceData} selectedTableData={selectedTableData} setSelectedTableData={setSelectedTableData} setCompilanceSaveClicked={setCompilanceSaveClicked} setCurrentSlide={setCurrentSlide}></AgentWin>
                </div>
            </div>
            {showModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                    >
                        <div className="relative w-[360px] my-6 mx-auto md:w-[50%]">
                            <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-main_bg focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                                    <h3 className="text-3xl font-semibold text-black">
                                        {t("Insight prompt")}
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-black outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div className="relative flex-auto p-6">
                                    <input type="text" id="first_name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-3" placeholder={t("Prompt Title")} required onChange={(e) => setPromptTitle(e.target
                                        .value)} value={promptTitle} />

                                    <textarea id="message" rows={4} className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder={t("Write your prompts here...")} onChange={(e) => setPromptText(e.target.value)} value={promptText}></textarea>

                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                                    <div className='flex text-black text-[16px] mr-auto cursor-pointer' onClick={() => {
                                        setGlobalInsight(!isGlobalInsight)
                                    }} >
                                        {isGlobalInsight ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>}
                                        <p className='ml-[5px]'>{t("Template prompt (Global)")}</p>
                                    </div>
                                    <div className='flex text-black text-[16px] mr-auto cursor-pointer' onClick={() => {
                                        setGlobalInsight(!isGlobalInsight)
                                    }}>
                                        {!isGlobalInsight ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>}
                                        <p className='ml-[5px]'>{t("Use towards selected folders (Locally)")}</p>
                                    </div>
                                    <button
                                        className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        {t("Close")}
                                    </button>
                                    {
                                        modalAction !== "Creation" ?
                                            <button
                                                className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-700 rounded shadow outline-none active:bg-red-500 hover:shadow-lg focus:outline-none"
                                                type="button"
                                                onClick={() => {
                                                    console.log(isGlobalInsight)
                                                    if (isGlobalInsight) {
                                                        setShowModal(false)
                                                        setShowInsightDeleteModal(true)
                                                        setGlobalInsightInModal(true)
                                                        setInsightIDForDelete(promptId)
                                                    } else {
                                                        setShowModal(false)
                                                        setShowInsightDeleteModal(true)
                                                        setGlobalInsightInModal(false)
                                                        setInsightIDForDelete(promptId)
                                                    }
                                                }
                                                }
                                            >
                                                {t("Delete")}
                                            </button> : <></>
                                    }
                                    <button
                                        className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                        type="button"
                                        onClick={() => handleSave()}
                                    >
                                        {t("Save Changes")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
            {
                showFeedBackModal ? <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                    >
                        <div className="relative w-[360px] my-6 mx-auto md:w-[50%]">
                            <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-main_bg focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                                    <h3 className="text-3xl font-semibold text-black">
                                        {t("AI Feedback Modal")}
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-black outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*body*/}
                                <div>
                                    <div className="relative flex-auto p-6 border-t border-solid rounded-b border-slate-200">
                                        <div className='flex text-black text-[16px] mb-[10px]' onClick={() => {
                                            setIsReferenceDocument(!isReferenceDocument)
                                        }}>
                                            {
                                                isReferenceDocument ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>
                                            }
                                            <p className='ml-[5px]'>{t('Reference Document:')}</p>
                                        </div>
                                        {
                                            isReferenceDocument ? <div className={`h-[180px]`}>
                                                <Combobox value={selected} onChange={setSelected}>
                                                    <div className="relative mt-1">
                                                        <div className="relative w-full overflow-hidden text-left cursor-default sm:text-sm">
                                                            <Combobox.Input
                                                                className="w-full py-2 pl-3 pr-10 text-sm leading-5 text-gray-900 border border-gray-300 border-none rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                                                                displayValue={(doc: any) => doc}
                                                                onChange={(event) => setQuery(event.target.value)}
                                                            />
                                                        </div>
                                                        <Transition
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                            afterLeave={() => setQuery("")}
                                                        >
                                                            <Combobox.Options className="absolute mt-1 max-h-[150px] w-full overflow-auto rounded-md bg-gray-500 text-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {filetedDoc?.length === 0 && query !== "" ? (
                                                                    <div className="relative px-4 py-2 text-white cursor-default select-none">
                                                                        Nothing found.
                                                                    </div>
                                                                ) : (
                                                                    filetedDoc.map((doc, index) => (
                                                                        <Combobox.Option
                                                                            key={index}
                                                                            className={({ active }) =>
                                                                                `relative cursor-default select-none py-2 pl-10 pr-4 ${active ? "bg-teal-600 text-white" : "text-gray-900"
                                                                                }`
                                                                            }
                                                                            value={doc}
                                                                        >
                                                                            {({ selected, active }) => (
                                                                                <>
                                                                                    <span
                                                                                        className={`block truncate ${selected ? "font-medium" : "font-normal"
                                                                                            }`}
                                                                                    >
                                                                                        {doc}
                                                                                    </span>
                                                                                    {selected ? (
                                                                                        <span
                                                                                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? "text-white" : "text-teal-600"
                                                                                                }`}
                                                                                        >
                                                                                            <CheckIcon
                                                                                                className="w-5 h-5"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        </span>
                                                                                    ) : null}
                                                                                </>
                                                                            )}
                                                                        </Combobox.Option>
                                                                    ))
                                                                )}
                                                            </Combobox.Options>
                                                        </Transition>
                                                    </div>
                                                </Combobox>
                                            </div> : <></>
                                        }
                                        <div className='flex text-black text-[16px] mb-[10px]' onClick={() => {
                                            setIsReferenceDocument(!isReferenceDocument)
                                        }}>
                                            {
                                                !isReferenceDocument ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>
                                            }
                                            <p className='ml-[5px]'>{t('Reference Keywords:')}</p>
                                        </div>
                                        {
                                            !isReferenceDocument ? <TagInput keyWords={keyWords} setKeyWords={setKeyWords}></TagInput> : <></>

                                        }


                                    </div>
                                    <div className='flex mt-[-25px]'>
                                        <div className="relative flex-auto p-6">
                                            <div className='flex text-black text-[16px] mb-[10px]'>
                                                <p className='ml-[5px]'>{t('Original Text:')}</p>
                                            </div>
                                            <textarea id="message" rows={12} className="scrollbar-hide block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" defaultValue={selectedText} disabled></textarea>
                                        </div>
                                        <div className="relative flex-auto p-6">
                                            <div className='flex text-black text-[16px] mb-[10px]'>
                                                <p className='ml-[5px]'>{t('AI Result:')}</p>
                                            </div>
                                            <textarea id="message" rows={12} className="scrollbar-hide block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                                        </div>
                                    </div>
                                    {/*footer*/}
                                    <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                                        <button
                                            className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                            type="button"
                                            onClick={() => { setShowFeedbackModal(false) }}
                                        >
                                            {t("Decline")}
                                        </button>
                                        {
                                            isGettingAnswer ?
                                                <div className='mr-[12px]'>
                                                    <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                                    </svg>
                                                    <span className="sr-only">Loading...</span>
                                                </div> : <button
                                                    className="mr-[8px] bg-emerald-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                                                    type="button"
                                                    onClick={() => {
                                                        getAIFeedback()
                                                    }}
                                                >
                                                    {t("Get AI Feedback")}
                                                </button>
                                        }
                                        <button
                                            className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear rounded shadow outline-none bg-emerald-500 active:bg-emerald-600 hover:shadow-lg focus:outline-none"
                                            type="button"
                                            onClick={() => {
                                                updateDocumentText(message)
                                            }}
                                        >
                                            {t("Accept")}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </> : <></>
            }

            {showInsightDeleteModal ? (
                <>
                    <div
                        className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none"
                    >
                        <div className="relative w-[360px] my-6 mx-auto md:w-[30%]">
                            <div className="relative flex flex-col w-full border-0 rounded-lg shadow-lg outline-none bg-main_bg focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid rounded-t border-slate-200">
                                    <h3 className="text-3xl font-semibold text-black">
                                        {t("Delete Confirmation Box")}
                                    </h3>
                                    <button
                                        className="float-right p-1 ml-auto text-3xl font-semibold leading-none text-black bg-transparent border-0 outline-none opacity-5 focus:outline-none"
                                        onClick={() => setShowInsightDeleteModal(false)}
                                    >
                                        <span className="block w-6 h-6 text-2xl text-black bg-black outline-none opacity-5 focus:outline-none">
                                            ×
                                        </span>
                                    </button>
                                </div>
                                {/*footer*/}

                                <div className="flex items-center justify-start p-6 border-t border-solid rounded-b border-slate-200">
                                    <h4 className='text-[black] text-[24px]'>{t("insight_delete_confirm_msg")}</h4>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid rounded-b border-slate-200">
                                    {
                                        isGlobalInsightInModal ? <>
                                            <div className='flex text-black text-[16px] mr-auto cursor-pointer' onClick={() => {
                                                setGlobalInsight(!isGlobalInsight)
                                            }} >
                                                {isGlobalInsight ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>}
                                                <p className='ml-[5px]'>{t("Delete globally")}</p>
                                            </div>
                                            <div className='flex text-black text-[16px] mr-auto cursor-pointer' onClick={() => {
                                                setGlobalInsight(!isGlobalInsight)
                                            }}>
                                                {!isGlobalInsight ? <CheckedIcon></CheckedIcon> : <UncheckedIcon></UncheckedIcon>}
                                                <p className='ml-[5px]'>{t("Delete selected folder")}</p>
                                            </div>
                                        </> : <></>
                                    }
                                    <button
                                        className="px-6 py-2 mb-1 mr-1 text-sm font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none"
                                        type="button"
                                        onClick={() => setShowInsightDeleteModal(false)}
                                    >
                                        {t("Close")}
                                    </button>

                                    <button
                                        className="px-6 py-3 mb-1 mr-1 text-sm font-bold text-white uppercase transition-all duration-150 ease-linear bg-red-700 rounded shadow outline-none active:bg-red-500 hover:shadow-lg focus:outline-none"
                                        type="button"
                                        onClick={() => {
                                            if (isGlobalInsightInModal) {
                                                handleDelete(insightIDForDelete, true);
                                            } else{
                                                handleDelete(insightIDForDelete, false)
                                            }
                                            setShowInsightDeleteModal(false)
                                        }}
                                    >
                                        {t("Delete")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 z-40 bg-black opacity-25"></div>
                </>
            ) : null}
        </>
    );
}

interface TagInputProps {
    keyWords: string
    setKeyWords: any;
}


const TagInput: React.FC<TagInputProps> = ({ keyWords, setKeyWords }) => {
    const [tags, setTags] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState('');

    // Split the keywords into individual tags when the component mounts or when keywords change
    useEffect(() => {
        const initialTags = keyWords.split(',').map((tag) => tag.trim()).filter((tag) => tag !== '');
        setTags(initialTags);
    }, [keyWords]); // Ensure tags are updated if keywords prop changes

    const updateKeywords = (updatedTags: string[]) => {
        setTags(updatedTags);
        const keywordsString = updatedTags.join(','); // Join the updated tags into a comma-separated string
        setKeyWords(keywordsString); // Call setKeywords with the updated string
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addTag(inputValue.trim());
        } else if (e.key === 'Backspace' && inputValue === '' && tags.length > 0) {
            removeTag(tags.length - 1);
        }
    };

    const addTag = (text: string) => {
        if (text && !tags.includes(text)) {
            const updatedTags = [...tags, text];
            updateKeywords(updatedTags); // Update the tag list and notify the parent component
            setInputValue(''); // Clear the input field after adding a tag
        }
    };

    const removeTag = (index: number) => {
        const updatedTags = tags.filter((_, i) => i !== index);
        updateKeywords(updatedTags); // Update the tag list and notify the parent component
    };

    return (
        <div className="flex flex-wrap items-center gap-2 px-4 py-3 border rounded">
            {tags.map((tag, index) => (
                <span key={index} className="bg-[#243C58] rounded-[10px] flex items-center px-2 py-1 text-white">
                    {tag}
                    <button
                        type="button"
                        className="ml-1 text-white"
                        onClick={() => removeTag(index)}
                    >
                        &times;
                    </button>
                </span>
            ))}
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="flex-grow text-black outline-none"
                placeholder="Type to add new"
            />
        </div>
    );
};