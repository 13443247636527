import React, { useEffect, useState } from 'react';
import './DocumentsTable.css'; // Import CSS for additional styles
import { ReactComponent as Icon2 } from "../../../assets/svg/insight_refresh.svg";
import UploadFilesModal from './UploadFiles';
import { parseJWT } from '../../../utils';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { saveAs } from 'file-saver';
// Type definitions
interface Document {
    name: string;
    description: string;
    found: string;
    lastUpdated: string;
    responsible: string;
    status: string;
}
const { default: axios } = require('axios');

const statusOptions = [
    { text: 'In Progress', className: 'bg-yellow-500', icon: '<path d="M12 19l9-7-9-7-9 7 9 7z"></path><path d="M12 12l9-7-9-7-9 7 9 7z"></path><line x1="12" y1="12" x2="12" y2="19"></line>' },
    { text: 'Missing', className: 'bg-red-500', icon: '<circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line>' },
    { text: 'Complete', className: 'bg-green-500', icon: '<path d="M5 12l5 5l10-10"></path>' },
    { text: 'Uncomplete', className: 'bg-gray-500', icon: '<circle cx="12" cy="12" r="10"></circle><path d="M14 10l-2 2-2-2m2 2V6"></path>' },
    { text: 'Automagic', className: 'bg-[#9B59B6]', icon: '<path d="M15 4V2M15 16V14M8 9H10M20 9H22M17.8 11.8L19 13M17.8 6.2L19 5M3 21L12 12M12.2 6.2L11 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>' },
    { text: 'Agent Win', className: 'bg-[#3498DB]', icon: '<path d="M12 15C8.68629 15 6 12.3137 6 9V3.44444C6 3.0306 6 2.82367 6.06031 2.65798C6.16141 2.38021 6.38021 2.16141 6.65798 2.06031C6.82367 2 7.0306 2 7.44444 2H16.5556C16.9694 2 17.1763 2 17.342 2.06031C17.6198 2.16141 17.8386 2.38021 17.9397 2.65798C18 2.82367 18 3.0306 18 3.44444V9C18 12.3137 15.3137 15 12 15ZM12 15V18M18 4H20.5C20.9659 4 21.1989 4 21.3827 4.07612C21.6277 4.17761 21.8224 4.37229 21.9239 4.61732C22 4.80109 22 5.03406 22 5.5V6C22 6.92997 22 7.39496 21.8978 7.77646C21.6204 8.81173 20.8117 9.62038 19.7765 9.89778C19.395 10 18.93 10 18 10M6 4H3.5C3.03406 4 2.80109 4 2.61732 4.07612C2.37229 4.17761 2.17761 4.37229 2.07612 4.61732C2 4.80109 2 5.03406 2 5.5V6C2 6.92997 2 7.39496 2.10222 7.77646C2.37962 8.81173 3.18827 9.62038 4.22354 9.89778C4.60504 10 5.07003 10 6 10M7.44444 22H16.5556C16.801 22 17 21.801 17 21.5556C17 19.5919 15.4081 18 13.4444 18H10.5556C8.59188 18 7 19.5919 7 21.5556C7 21.801 7.19898 22 7.44444 22Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'},
];
interface DocumentsTableProps {
    documentsForSubmission: any
    isLoading: any
    selectedFolderID: any
    handleDocumentClick: any
    tenderList: any
    getDocumentsForSubmission: any
    setTenderList: any
    setOpenedStatus: any
    handleSelectedFolder: any
    selectedTab: any
    setTabName: any
    setcurrentSlide: any
    setAutoFilling: any
    setRunSync: any
}
const DocumentsTable: React.FC<DocumentsTableProps> = ({ documentsForSubmission, isLoading, selectedFolderID, handleDocumentClick, tenderList, getDocumentsForSubmission, setTenderList, setOpenedStatus, handleSelectedFolder, selectedTab, setTabName, setcurrentSlide, setAutoFilling, setRunSync }) => {
    const initialDocuments: Document[] = [
        { name: "Company Registration Certificate", description: "Certificate of company registration", found: "company_certificate.pdf", lastUpdated: "2024-06-25 14:30", responsible: "", status: "Complete" },
        { name: "Proof of Paid Taxes and Fees", description: "Confirmation of paid taxes and fees", found: "", lastUpdated: "", responsible: "", status: "Missing" },
        { name: "Supplier's Solution Response", description: "Detailed description of solution", found: "solution.pdf", lastUpdated: "2024-06-24 09:15", responsible: "", status: "In Progress" }
    ];
    let user = parseJWT(localStorage.getItem("tender_auth"));
    const [keyWords, setKeyWordsString] = useState<any>([])
    const [updatefile, setUpdatedFile] = useState<any>("")
    const [requirement, setRequirement] = useState<any>("")
    const [updatefileIndex, setUpdatedFileIndex] = useState<any>(-1)
    const [isModalOpen, setModalOpen] = useState<any>(false)
    const [isAddingRow, setAddingRow] = useState<any>(false)
    const [companyBaseTenderID, setCompanyBaseTenderID] = useState<any>()
    const { t } = useTranslation();
    useEffect(() => {
        getCompanyBaseTenderID()
    }, [])
    const getCompanyBaseTenderID = async () => {
        const data = new FormData();
        data.append("email", user.sub.email);
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/getCompanyBaseTenderID", data, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        setCompanyBaseTenderID(result.data.Id);
    };
    const setKeyWords = async (keywordsString: any, key: any) => {
        keyWords[key] = keywordsString
        console.log(key, "-", keywordsString)
        const formData: any = new FormData();
        formData.append('tender_id', selectedFolderID)
        formData.append('doc_id', key)
        formData.append('taskOwners', keywordsString)
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/saveChangesSubmission", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200) {
        }
        setKeyWordsString(keyWords)
    }
    const [documents, setDocuments] = useState<any[]>(initialDocuments);
    const [uploadingState, setUploadingState] = useState(-1);
    useEffect(() => {
        let docs: any = []
        var owners = new Array
        try {
            for (let index = 0; index < documentsForSubmission?.length; index++) {
                let taskowner = documentsForSubmission[index]?.taskOwners ? documentsForSubmission[index]?.taskOwners : ""
                let name = documentsForSubmission[index]?.id
                owners[name] = taskowner;
                // let desripton_json = JSON.parse(documentsForSubmission[index]?.description)
                let doc: any = {}
                doc.found = documentsForSubmission[index]?.id
                // doc.name = desripton_json?.name
                // doc.description = desripton_json?.description
                doc.description = documentsForSubmission[index]?.description
                doc.spaceType = documentsForSubmission[index]?.spaceType
                doc.status = documentsForSubmission[index]?.status
                doc.folder = documentsForSubmission[index]?.spaceType
                doc.lastUpdated = documentsForSubmission[index]?.update_time
                docs = [...docs, doc];
            }
            setKeyWordsString(owners)
            setDocuments(docs)
        } catch (error) {
            console.log(error)
        }

    }, [documentsForSubmission])
    const downloadZipDocuments = async () => {
        const data = new FormData();
        data.append("tender_id", selectedFolderID);
        data.append("email", user.sub.email);
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/downloadDocumentsForSubmission", data, {
            responseType: 'blob'
        });
        const blob = new Blob([result.data], { type: 'application/zip' });
        const zipFileName = tenderList.filter(item => item.id === selectedFolderID)[0]?.title + '.zip';
        saveAs(blob, zipFileName);
    }
    const addDocuments = (newDocs) => {
        getDocumentsForSubmission("normal")
    }

    const addRow = () => {
        setDocuments([...documents, {
            name: requirement,
            description: requirement,
            found: '',
            lastUpdated: '',
            responsible: '',
            status: 'Missing'
        }]);
    };
    const confirmRow = () => {
        setAddingRow(true)
    };
    const deleteDocumentforSubmission = () => {
        if (updatefile != "") {
            if (documents[updatefileIndex]?.spaceType == "work") {
                deleteRow(updatefileIndex, updatefile)
            }
            setUpdatedFile("")
            setRequirement("")
        }
    }
    const deleteRow = async (index: any, doc_id) => {
        const formData: any = new FormData();
        formData.append('tender_id', selectedFolderID)
        if (doc_id) {
            formData.append('doc_id', doc_id)
        }
        formData.append('description', documents[index].description)
        formData.append('email', user.sub.email)
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/deleteDocumentforSubmission", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200) {
            setDocuments(documents.filter((_, i) => i !== index));
            const data = new FormData();
            data.append("email", user.sub.email);
            data.append("tab", selectedTab);
            const result = await axios.post(process.env.REACT_APP_API_URL + "api/getTenders", data, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                    "Content-Type": "application/json",
                }
            });
            let newIndex = result.data.data.filter(item => item.isFolderDeleted === 0).length
            setTenderList(result.data.data)
            handleSelectedFolder(selectedFolderID, result.data.data[newIndex > 0 ? newIndex - 1 : 0]?.title)
        }
    };

    const changeStatus = (index: any, status: string, id: string) => {
        toggleDropdown(index)
        saveStatus(id, status)
        if (status === "Agent Win") {
            setcurrentSlide(3)
            setRunSync(true)
        }
        if (status === "Automagic") {
            handleDocumentClick(documents[index].found, null, documents[index].folder, companyBaseTenderID)
            setcurrentSlide(2)
            setAutoFilling(true)
        }
        const updatedDocuments = [...documents];
        updatedDocuments[index].status = status;
        setDocuments(updatedDocuments);
    };
    const saveStatus = async (index: any, status: string) => {
        const formData: any = new FormData();
        formData.append('tender_id', selectedFolderID)
        formData.append('doc_id', index)
        formData.append('status', status)
        const result = await axios.post(process.env.REACT_APP_API_URL + "api/saveChangesSubmission", formData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("tender_auth")}`,
                "Content-Type": "application/json",
            }
        });
        if (result.status === 200) {
        }
    }
    const toggleDropdown = (index: any) => {
        const dropdown = document.querySelector(`#dropdown-${index} .dropdown-content`);
        if (dropdown) {
            dropdown.classList.toggle('hidden');
        }
    };
    const closeModal = () => {
        setAddingRow(false)
    }
    return (
        <div className="p-6 text-black bg-gray-100">
            {isAddingRow == true&&(<>
                <div className="text-black fixed inset-0 flex items-center justify-center bg-opacity-75 p-4 z-[100]">
                    <div className="flex flex-col w-full max-w-3xl bg-white rounded-lg shadow-lg">
                        {/* Modal Header */}
                        <div className="flex items-center justify-between p-4">
                            <div className="flex items-center">
                                {/* Placeholder for Logo */}
                                <img src={process.env.PUBLIC_URL + '/img/Filter_page1.png'} width={50} height={50} className='mr-[10px]'></img>
                                <h2 className="text-3xl font-semibold text-black">{t("Agent Insights")}</h2>
                            </div>
                        </div>
                        <div className='font-medium text-black text-[25px]'>{t('Please enter document name')}</div>
                        <input type="text" className='width-[100%] m-5 border border-black rounded p-4' onChange={(e) => setRequirement(e.target.value)}/>
                        {/* Modal Footer */}
                        <div className="flex justify-end p-4 mt-auto">
                            <button
                                onClick={closeModal}
                                className="px-6 py-2 mb-1 mr-1 font-bold text-red-500 uppercase transition-all duration-150 ease-linear outline-none background-transparent focus:outline-none text-[20px]"
                            >
                                {t("Close")}
                            </button>
                            <button
                                onClick={async () => {
                                    addRow()
                                    closeModal()
                                }}
                                className="px-2 py-1 font-bold text-white bg-green-500 rounded active:bg-green-600 active:border-green-700 active:text-white text-[20px]"
                                style={{ backgroundColor: '#55B685', width: 120, height: 50 }}
                            >
                                {t("SAVE")}
                            </button>
                        </div>
                    </div>
                </div>
            </>)}
            {isModalOpen && (<UploadFilesModal requirement={requirement} setTabName={setTabName} tenderID={selectedFolderID} tenderList={tenderList} setModalOpen={setModalOpen} addDocuments={addDocuments} setTenderList={setTenderList} setOpenedStatus={setOpenedStatus} handleSelectedFolder={handleSelectedFolder} deleteDocumentforSubmission={deleteDocumentforSubmission}></UploadFilesModal>)}
            <h1 className="mb-6 text-xl font-bold text-left">{t("Documents for Submission")}</h1>
            <table className="min-w-full">
                <thead className='text-white'>
                    <tr>
                        <th className="w-1/3 px-4 py-2 text-xl font-bold text-left bg-cyan-700 custom-header">{t("Document Name")}</th>
                        <th className="w-1/3 px-4 py-2 text-xl font-bold text-left bg-cyan-700 custom-header">{t("Document")}</th>
                        <th className="w-1/6 px-4 py-2 text-xl font-bold text-left bg-cyan-700 custom-header">{t("Task Owner")}</th>
                        <th className="w-1/6 px-4 py-2 text-xl font-bold text-left bg-cyan-700 custom-header">{t("Status")}</th>
                        <th className="w-1/12 bg-opacity-0"></th>
                    </tr>
                </thead>
                <tbody>
                    {isLoading ? (
                        <>
                            <tr className='h-[80px] bg-white'>
                                <td colSpan={5} className='text-center'>
                                    <div className='flex items-center justify-center'>
                                        <Icon2 className='animate-spin' />
                                    </div>
                                </td>
                            </tr>
                        </>
                    )
                        : documents.map((doc, index) => (
                            <tr key={index} id={`row-${index}`} className='text-black'>
                                {index == uploadingState ? <>
                                    <td colSpan={5} className=' text-center h-[80px] bg-white'>
                                        <div className='flex items-center justify-center'>
                                            <Icon2 className='animate-spin' />
                                        </div>
                                    </td></> :
                                    <>
                                        <td className="px-4 py-2 text-xl font-bold text-left bg-white border">
                                            {/* {doc.name}
                                            <br /> */}
                                            <span className="text-sm font-normal">{doc.description}</span>
                                        </td>
                                        <td className="px-4 py-2 text-left bg-white border" id={`document-${index}`}>
                                            {doc.found ? (
                                                <>
                                                    <p className='cursor-pointer hover:underline' onClick={() => handleDocumentClick(doc.found, null, doc.folder, companyBaseTenderID)}>{doc.found}</p>
                                                    <br />
                                                    <span className="flex text-sm text-gray-500">
                                                        <span>File last updated: {doc.lastUpdated}</span>
                                                        <button className="flex ml-2 custom-text" onClick={() => {
                                                            setUpdatedFileIndex(index)
                                                            setUpdatedFile(doc.found)
                                                            setRequirement(doc.description)
                                                            setModalOpen(true)
                                                        }
                                                        }>
                                                            <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                            {t("Change")}
                                                        </button>
                                                    </span>
                                                </>
                                            ) : (
                                                <>
                                                    <button className="flex items-center px-2 py-1 rounded custom-button" onClick={
                                                        () => {
                                                            setRequirement(doc.description)
                                                            setModalOpen(true)
                                                        }
                                                    }>
                                                        <svg className="w-4 h-4 mr-2" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path><polyline points="17 8 12 3 7 8"></polyline><line x1="12" y1="3" x2="12" y2="15"></line></svg>
                                                        {t("Upload")}
                                                    </button>
                                                </>
                                            )}
                                        </td>
                                        <td className="px-4 py-2 bg-white border">
                                            <TagInput setKeyWords={setKeyWords} keyWords={keyWords[doc.found] ? keyWords[doc.found] : ""} index={doc.found} />
                                        </td>
                                        <td className="px-2 py-2 bg-white border">
                                            <div className="relative flex justify-start inline-block" id={`dropdown-${index}`}>
                                                <span
                                                    className={`status-tag whitespace-nowrap inline-flex items-center px-3 py-1.5 mx-1 text-white ${statusOptions.find(opt => opt.text === doc.status)?.className} rounded-full cursor-pointer`}
                                                    id={`current-tag-${index}`}
                                                    onClick={() => toggleDropdown(index)}
                                                >
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        {/* Render SVG path directly from the status option */}
                                                        <g dangerouslySetInnerHTML={{ __html: statusOptions.filter(opt => opt.text === doc.status)[0]?.icon }} />
                                                    </svg>
                                                    {t(doc.status)}
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5 ml-1" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                                                    </svg>
                                                </span>
                                                <ul className="absolute left-0 z-10 hidden w-40 mt-10 overflow-hidden bg-white border border-gray-200 rounded-lg shadow-lg dropdown-content">
                                                    {statusOptions.map(opt => (
                                                        <li key={opt.text} className="flex items-center px-4 py-2 cursor-pointer hover:bg-gray-100" onClick={() => changeStatus(index, opt.text, doc.found)}>
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                                <g dangerouslySetInnerHTML={{ __html: opt.icon }} />
                                                            </svg>
                                                            {t(opt.text)}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </td>
                                        <td className="no-border">
                                            {doc.spaceType == "company" ?
                                                <></> :
                                                <button className="custom-text" onClick={() => deleteRow(index, doc.found)}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-trash trash-icon" width="24" height="24" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <line x1="4" y1="7" x2="20" y2="7"></line>
                                                        <line x1="10" y1="11" x2="10" y2="17"></line>
                                                        <line x1="14" y1="11" x2="14" y2="17"></line>
                                                        <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12"></path>
                                                        <path d="M9 7v-3h6v3"></path>
                                                    </svg>
                                                </button>

                                            }
                                        </td>
                                    </>
                                }
                            </tr>
                        ))}
                </tbody>
            </table>
            <div className='flex items-center justify-between'>
                <button onClick={confirmRow} className="px-4 py-2 mt-4 rounded custom-button">
                    {t("Add Row")}
                </button>
                <a onClick={downloadZipDocuments} className="cursor-pointer download-link">{t("Download all files")} (zip)</a>
            </div>
        </div>
    );
};

export default DocumentsTable;


interface TagInputProps {
    keyWords: string
    setKeyWords: any
    index: any
}


const TagInput: React.FC<TagInputProps> = ({ keyWords, setKeyWords, index }) => {
    const [tags, setTags] = useState<string[]>([]);
    const [inputValue, setInputValue] = useState('');

    // Split the keywords into individual tags when the component mounts or when keywords change
    useEffect(() => {
        const initialTags = keyWords.split(',').map((tag) => tag.trim()).filter((tag) => tag !== '');
        setTags(initialTags);
    }, [keyWords]); // Ensure tags are updated if keywords prop changes

    const updateKeywords = (updatedTags: string[]) => {
        setTags(updatedTags);
        const keywordsString = updatedTags.join(','); // Join the updated tags into a comma-separated string
        setKeyWords(keywordsString, index); // Call setKeywords with the updated string
    };

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter' || e.key === ',') {
            e.preventDefault();
            addTag(inputValue.trim());
        } else if (e.key === 'Backspace' && inputValue === '' && tags.length > 0) {
            removeTag(tags.length - 1);
        }
    };

    const addTag = (text: string) => {
        if (text && !tags.includes(text)) {
            const updatedTags = [...tags, text];
            updateKeywords(updatedTags); // Update the tag list and notify the parent component
            setInputValue(''); // Clear the input field after adding a tag
        }
    };

    const removeTag = (index: number) => {
        const updatedTags = tags.filter((_, i) => i !== index);
        updateKeywords(updatedTags); // Update the tag list and notify the parent component
    };

    return (
        <div className="flex flex-wrap items-center gap-2 px-4 py-3 border rounded">
            {tags.map((tag, index) => (
                <span key={index} className="bg-[#243C58] rounded-[10px] flex items-center px-2 py-1 text-white">
                    {tag}
                    <button
                        type="button"
                        className="ml-1 text-white"
                        onClick={() => removeTag(index)}
                    >
                        &times;
                    </button>
                </span>
            ))}
            <input
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="flex-grow text-black outline-none"
                placeholder="Type to add"
            />
        </div>
    );
};